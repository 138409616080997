<template>
  <a-spin :spinning="isLoading">
    <div class="announcements-detail">
      <Header collection="Newsfeed"></Header>
      <div class="announcements-body">
        <div class="list-item">
          <div v-html="item.title"></div>
          <span>{{ item.start_time && item.start_time.split(" ")[0] }}</span>
        </div>
        <div class="line_"></div>
        <div class="detail">
          <img
            v-if="!!item.pic"
            :src="
              `${CONFIG.OSS_HOST}${CONFIG.OSS_HOST.endsWith('/') ? '' : '/'}${
                item.pic
              }`
            "
            alt=""
          />
          <!-- <div class="detail-bold">#HotOffer at LinkPar</div> -->
          <div class="detail-text" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import Header from "@/components/public/Header.vue";
import CONFIG from "@/utils/config";
import axios from "@/utils/request";

export default {
  components: {
    Header,
  },
  data() {
    return {
      item: {},
      CONFIG,
      isLoading: false,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const { id } = this.$route.params;
      this.isLoading = true;
      axios
        .get("m/finances/c/index/a/noticeDetail", {
          params: {
            id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.item = res.payload;
          } else {
            this.$message.error(res.msg || "");
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.announcements-detail {
  .announcements-body {
    width: 90.5714rem;
    min-height: 49.2857rem;
    background: #ffffff;
    border-radius: 16px;
    padding: 2.2857rem;
    margin-top: 1.7143rem;
    .line_ {
      width: 100%;
      height: 1px;
      background: #f5f5f5;
      margin: 1.2857rem 0;
    }
    .list-item {
      display: flex;
      align-items: center;
      font-size: 1.1429rem;
      justify-content: space-between;
      margin: 1.2857rem 0;
      span {
        color: #999999;
      }
    }
    .detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3.4286rem;
      img {
        width: 53.5714rem;
        height: 19.5714rem;
        border-radius: 16px;
      }
      &-bold {
        font-size: 1.1429rem;
        font-family: "prefer-bold";
        margin-top: 2.0714rem;
        margin-bottom: 1.1429rem;
      }
      &-text {
        color: #333333;
        width: 41.1429rem;
        margin-top: 30px;
      }
    }
  }
}
</style>
